import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "shards-react";
import { DebounceInput, Page, Preloader } from "lib/common";
import { FiCheckSquare, FiPlus, FiPlusCircle } from "react-icons/fi";
import { Tab, Tabs } from "nlib/ui";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { checkTasksFetching, getTasksData } from "selectors/tasks";
import { getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BoardView from "nlib/pages/TasksPage/lib/BoardView";
import Constants from "const/Constants";
import FilterByUser from "nlib/pages/TasksPage/lib/FilterByUser";
import ListView from "nlib/pages/TasksPage/lib/ListView";
import MonthsTabs from "nlib/pages/TasksPage/lib/MonthsTabs";
import MonthsTabsVertical from "nlib/pages/TasksPage/lib/MonthsTabsVertical";
import PageHeader from "lib/common/PageHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TasksActions from "actions/TasksActions";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const VIEW_NAMES = {
  LIST: "list",
  PERIODS: "periods",
  BOARD: "board"
};

const TASK_TARGET_USERS = {
  ACCOUNTANT: "accountant",
  CLIENT: "client",
  CURRENT_USER: "currentUser"
};

const TasksPage = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { section: currentView = VIEW_NAMES.LIST } = useParams();

  const [envVars, setEnvVars] = useEnvVars();

  const { fromDate, toDate, text } = envVars;

  const businessUser = useSelector(checkIsBusinessUser);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const fetchingData = useSelector(checkTasksFetching);

  const { uiTexts, messages } = useSelector(getTextsData);

  const allUsersData = useSelector(getAllUsersData);

  const allTasksData = useSelector(getTasksData);

  const userData = useSelector(getUserData);

  const [searchText, setSearchText] = useState(text || "");

  const filteredTasksData = useMemo(() => {
    if (!envVars.text) return allTasksData;

    const searchString = envVars.text.trim().toLowerCase();

    return allTasksData.filter(({ preview }) => [preview].some((item) => item.toLowerCase().includes(searchString)));
  }, [envVars.text, allTasksData]);

  const tasksFilteredByUser = useMemo(() => {
    if (!envVars.targetUser) return filteredTasksData;

    switch (envVars.targetUser) {
      case TASK_TARGET_USERS.ACCOUNTANT:
        return filteredTasksData.filter(({ targetUser }) => !targetUser || UserRoles.checkIsAccountant(targetUser?.role));
      case TASK_TARGET_USERS.CLIENT:
        return filteredTasksData.filter(({ targetUser }) => UserRoles.checkIsBusiness(targetUser?.role));
      case TASK_TARGET_USERS.CURRENT_USER:
        return filteredTasksData.filter(({ targetUser }) => targetUser?.id === userData.id || (!targetUser && !businessUser));
      default:
        return filteredTasksData.filter(({ targetUser }) => (targetUser?.id === envVars.targetUser)
          || (!targetUser && !UserRoles.checkIsBusiness(Utils.arrayFindById(allUsersData, envVars.targetUser)?.role)));
    }
  }, [envVars.targetUser, filteredTasksData, userData.id, businessUser, allUsersData]);

  const filteredTasksByPeriod = useMemo(() => {
    if (!fromDate && !toDate) return tasksFilteredByUser;

    return tasksFilteredByUser.filter(({ startDate }) => {
      const momentDate = moment.utc(startDate);

      return (!fromDate || momentDate.isSameOrAfter(moment.utc(fromDate).startOf("day")))
        && (!toDate || momentDate.isSameOrBefore(moment.utc(toDate).endOf("day")));
    });
  }, [fromDate, tasksFilteredByUser, toDate]);

  const handleAddTaskButtonClick = useCallback(() => {
    setEnvVars({ editTask: Constants.NEW_ENTITY_ID });
  }, [setEnvVars]);

  const handleViewChange = useCallback((nextView) => {
    history.push(`/${selectedBusinessId}${UiRoutes.TASKS}/${nextView}`);
    Utils.storageValue(Constants.LS_KEYS.TASKS_VIEW_LAST_TAB, nextView);
  }, [history, selectedBusinessId]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  }, []);

  const handleSearchComplete = useCallback((value) => {
    setEnvVars({ text: value.trim() || null });
  }, [setEnvVars]);

  const handleResetClick = useCallback(() => {
    setEnvVars({ text: null, targetUser: null, fromDate: null, toDate: null });
  }, [setEnvVars]);

  useEffect(() => {
    dispatch(TasksActions.fetchTasksList());
  }, [dispatch]);

  return (
    <Page className={Css.tasksPage}>
      <PageHeader />
      <div className={Css.subHeader}>
        <div>
          {!businessUser && (
            <Tabs current={currentView} onChange={handleViewChange}>
              <Tab value={VIEW_NAMES.LIST}>
                <Icons.List />
                <span>{uiTexts.list}</span>
              </Tab>
              <Tab value={VIEW_NAMES.PERIODS}>
                <Icons.Checks />
                <span>{uiTexts.periods}</span>
              </Tab>
              <Tab value={VIEW_NAMES.BOARD}>
                <Icons.Kanban />
                <span>{uiTexts.board}</span>
              </Tab>
            </Tabs>
          )}
        </div>
        {!!allTasksData.length && (
          <div className={Css.filters}>
            {(!!envVars.text || envVars.targetUser || envVars.fromDate || envVars.toDate) && (
              <Button
                outline
                theme="danger"
                className={Css.reset}
                onClick={handleResetClick}>
                <Icons.X />
                <span>{uiTexts.resetFilters}</span>
              </Button>
            )}
            <DebounceInput
              placeholder={uiTexts.searchTask}
              className={Css.search}
              value={searchText}
              onChange={handleSearchChange}
              onInputComplete={handleSearchComplete} />
            <FilterByUser className={Css.filter} />
            {((envVars.editTask || "").split(".")[0] !== Constants.NEW_ENTITY_ID) && (
              <Button
                className={Css.button}
                onClick={handleAddTaskButtonClick}>
                <FiPlus />
                <span>{uiTexts.addNewTask}</span>
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={Css.container}>
        {allTasksData.length
          ? (
            currentView === VIEW_NAMES.BOARD
              ? (
                <>
                  <MonthsTabs
                    data={tasksFilteredByUser} />
                  <BoardView tasksData={filteredTasksByPeriod} />
                </>
              )
              : (
                <div className={Css.content}>
                  {(currentView === VIEW_NAMES.PERIODS) && (
                    <MonthsTabsVertical
                      data={tasksFilteredByUser} />
                  )}
                  <ListView tasksData={filteredTasksByPeriod} />
                </div>
              )
          )
          : (fetchingData ? <Preloader absolute /> : (
            <div className={Css.emptyState}>
              <div className={Css.icon}><FiCheckSquare /></div>
              <h4>{messages.tasksBlockPlaceholder}</h4>
              <Button size="sm" onClick={handleAddTaskButtonClick}>
                <FiPlusCircle />
                <span>{uiTexts.addNewTask}</span>
              </Button>
            </div>
          ))}
      </div>
    </Page>
  );
};

export default TasksPage;
